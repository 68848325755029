import React from "react"

import Layout from "../components/Layout"
import * as data from "../assets/js/data"

const copy = {
  en: {
    title: "Information for Newcomers",
  },
  cn: {
    title: "新移民資訊​",
  },
}

const Information = ({ lang }) => {
  const translate = key => copy[lang][key]
  const topics = data.topicCopy[lang]

  return (
    <Layout lang={lang}>
      <section className="header">
        <h1 className="info__header__title">{translate("title")}</h1>
      </section>
      <section className="information">
        {Object.entries(topics).map(([id, topic]) => (
          <a
            key={id}
            className="info__item"
            href={
              lang === "cn"
                ? `/cn/information/topic/?id=${id}`
                : `/information/topic/?id=${id}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <div className={`info__item-image o__${id}`} />
            <div className="info__text">
              <h2 className="info__title">{topic.title}</h2>
              <h4 className="info__description">{topic.description}</h4>
            </div>
          </a>
        ))}
      </section>
    </Layout>
  )
}

export default Information
